<template>
  <div class="box">
    <router-view></router-view>
  </div>
</template>
<style scoped>
.box{
  height: 100%;
  width: 100%;
}
</style>