import { 
  createRouter
  , createWebHashHistory
  // , createWebHistory 
} from 'vue-router';

const routes = [
  {
    path: '/',
    component: () => import('@/components/login/login.vue')
  },
  {
    path: '/sys',
    component: () => import('@/components/home/homePage.vue'),
    children:[
      {
        path:'/menu',
        name:"菜单管理",
        component:  () => import('@/components/view/menuManger.vue'),
        meta: { title: '菜单管理', icon: 'example' },
      },
      {
        path:'/role',
        name:"角色管理",
        component:  () => import('@/components/view/roleManager.vue'),
      },
      {
        path:'/zone',
        name:"地区管理",
        component:  () => import('@/components/view/zoneManager.vue'),
      },
      {
        path:'/village',
        name:"村子管理",
        component:  () => import('@/components/view/villageManager.vue'),
      },
      {
        path:'/function',
        name:"权限管理",
        component:  () => import('@/components/view/functionManager.vue'),
      },
      {
        path:'/user',
        name:"用户列表",
        component:  () => import('@/components/view/userManager.vue'),
      },
      {
        path:'/whSetting/class',
        name:"商品类别设置",
        component:  () => import('@/components/view/wh/setClass.vue'),
      },
      {
        path:'/whSetting/product',
        name:"商品设置",
        component:  () => import('@/components/view/wh/setProduct.vue'),
      },
      {
        path:'/product/add',
        name:"新增商品",
        component:  () => import('@/components/form/wh/productForm.vue'),
        meta: { title: '新增商品', icon: 'tree' },
        hidden:true
      },
      {
        path: '/menu/edit/:id',
        name: '菜单修改',
        component: () => import('@/components/form/menuForm.vue'),
        meta: { title: '讲师修改', icon: 'tree' },
        hidden:true
      },
      {
        path: '/menu/add',
        name: '菜单新增',
        component: () => import('@/components/form/menuForm.vue'),
        meta: { title: '讲师新增', icon: 'tree' },
        hidden:true
      },
      {
        path: '/role/edit/:id',
        name: '角色修改',
        component: () => import('@/components/form/roleForm.vue'),
        meta: { title: '讲师修改', icon: 'tree' },
        hidden:true
      },
      {
        path: '/role/add',
        name: '角色新增',
        component: () => import('@/components/form/roleForm.vue'),
        meta: { title: '讲师新增', icon: 'tree' },
        hidden:true
      },
      {
        path: '/role/userRole/:id',
        name: '给用户分配角色',
        component: () => import('@/components/form/setUserRole.vue'),
        meta: { title: '讲师新增', icon: 'tree' },
        hidden:true
      },
      {
        path: '/zone/addChild/:parentCode',
        name: '地区新增子地区',
        component: () => import('@/components/form/zoneAddForm.vue'),
        meta: { title: '地区新增子地区', icon: 'tree' },
        hidden:true
      },
      {
        path: '/function/add',
        name: '权限新增',
        component: () => import('@/components/form/functionForm.vue'),
        meta: { title: '权限新增', icon: 'tree' },
        hidden:true
      },
      {
        path: '/function/edit/:id',
        name: '权限修改',
        component: () => import('@/components/form/functionForm.vue'),
        meta: { title: '权限修改', icon: 'tree' },
        hidden:true
      },
      {
        path: '/village/add',
        name: '村子新增',
        component: () => import('@/components/form/villageForm.vue'),
        meta: { title: '村子新增', icon: 'tree' },
        hidden:true
      },
      {
        path: '/village/edit/:id',
        name: '村子分配负责人',
        component: () => import('@/components/form/villageForm.vue'),
        meta: { title: '村子分配负责人', icon: 'tree' },
        hidden:true
      },
      {
        path: '/user/center',
        name: '个人中心',
        component: () => import('@/components/view/userCenter.vue'),
        meta: { title: '个人中心', icon: 'tree' },
        hidden:true
      },
      {
        path: '/order/list',
        name: '订单列表',
        component: () => import('@/components/view/order/list.vue'),
        meta: { title: '个人中心', icon: 'tree' },
        hidden:true
      },
    ]
  },
  {
    path: '/wx',
    component: () => import('@/components/wxView/base.vue'),
    meta: { title: '春阳服务部' },
    children:[
      {
        path: '/wx/to/:path',
        name: '推送跳转',
        component: () => import('@/components/wxView/base.vue'),
      },
      {
        path:'/wx/main',
        name:"微信首页",
        component:  () => import('@/components/wxView/mainPage.vue'),
        meta: { title: '微信首页', icon: 'example' }
      },
      {
        path:'/wx/my',
        name:"微信个人中心",
        component:  () => import('@/components/wxView/myPage.vue'),
        meta: { title: '微信个人中心', icon: 'example' }
      },
      {
        path:'/wx/my/auth',
        name:"微信认证中心",
        component:  () => import('@/components/wxView/view/myAuth.vue'),
        meta: { title: '微信个人中心', icon: 'example' }
      },
      {
        path:'/wx/my/info',
        name:"微信认证信息",
        component:  () => import('@/components/wxView/view/myInfo.vue'),
        meta: { title: '微信个人中心', icon: 'example' }
      },
      {
        path:'/wx/repair',
        name:"报修页面",
        component:  () => import('@/components/wxView/view/repair.vue'),
        meta: { title: '在线报修', icon: 'example' }
      },
      {
        path:'/wx/copy',
        name:"抄表页面",
        component:  () => import('@/components/wxView/view/copy.vue'),
        meta: { title: '在线抄表', icon: 'example' }
      },
      {
        path:'/wx/meterRecord',
        name:'抄表记录页面',
        component: () => import('@/components/wxView/view/meterRecord.vue')
      },
      {
        path: '/wx/statistics',
        name: '水费统计中心',
        component: () => import('@/components/wxView/view/statistics.vue')
      },
      {
        path:'/wx/myRecords',
        name:'用水记录查询',
        component: () =>import('@/components/wxView/view/myRecord.vue')
      },
      {
        path: '/wx/repair/add',
        name: '在线报修',
        component: () => import('@/components/form/repairForm.vue'),
        meta: { title: '权限修改', icon: 'tree' },
        hidden:true
      },
      {
        path: '/wx/repair/edit/:id',
        name: '报修详情',
        component: () => import('@/components/form/repairForm.vue'),
        meta: { title: '权限修改', icon: 'tree' },
        hidden:true
      },
      {
        path: '/wx/streetManager',
        name: '街道管理',
        component: () => import('@/components/wxView/view/streetManager.vue'),
        meta: { title: '街道管理', icon: 'tree' }
      },
      {
        path: '/wx/homeNoManager',
        name: '门牌管理',
        component: () => import('@/components/wxView/view/homeNoManager.vue'),
        meta: { title: '街道管理', icon: 'tree' }
      },
      {
        path: '/wx/replaceWaterMeter',
        name: '更换水表',
        component: () => import('@/components/wxView/view/replaceWaterMeter.vue'),
        meta: { title: '更换水表', icon: 'tree' }
      }
    ]
  },
  {
    path: '/film',
    component: () => import('@/components/HelloWorld.vue')
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/"
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/login"
  }, 
  {
    path: "/:pathMatch(.*)*",
    redirect: "/login"
  },
];


const router = createRouter({
  history: createWebHashHistory(),  // hash路由模式
  // history: createWebHistory(),  // history路由模式
  routes
});

export default router;
