import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from '@/router/index.js'
import axios from '@/utils/axiosInstance.js'
import AES from './utils/AES';
// 引入echarts
import echarts from 'echarts'
import mitt from 'mitt'

const app = createApp(App);
app.use(ElementPlus).use(router).use(echarts).use(mitt).mount('#app')
axios.defaults.baseURL = process.env.NODE_ENV === "production"?"http://www.villageserver.cn:80/msc/":"http://localhost:8080/api";
app.config.globalProperties.$axios=axios;

app.config.globalProperties.$aes = AES;
