//axiosInstance.js
//导入axios
import axios from 'axios'
import router from '@/router';
import { ElMessage } from 'element-plus';

//使用axios下面的create([config])方法创建axios实例，其中config参数为axios最基本的配置信息。
const API = axios.create({
	baseUrl: process.env.NODE_ENV === 'production'?'http://www.villageserver.cn:80/msc/':'http://localhost:80/', //请求后端数据的基本地址，自定义
	timeout: 20000,                 //请求超时设置，单位ms	
})

//请求拦截器----在项目中发请求（请求没有发出去）可以做一些事情
API.interceptors.request.use((config) => {
	//现在的问题是config是什么?配置对象
   config.headers.AuthToken = localStorage.getItem('token');
   config.headers.WxAuthToken = localStorage.getItem('wxtoken');
   config.withCredentials=true;//设置cross跨域 并设置访问权限 允许跨域携带cookie信息
	return config;
  });
   
  //响应拦截器----当服务器手动请求之后，做出响应（相应成功）会执行的
  API.interceptors.response.use(
	(res) => {
		if(res.data.code == 200) {
			return res.data;
		} else {
			// ElMessage.error(res.data.message);
			if(res.data.code == 201) {
				router.push('/')
			}
			return res.data;
		} 
		
	  //相应成功做的事情
	  
	},
	(err) => {
	  alert("服务器响应数据失败" + err);
	}
  );

//导出我们建立的axios实例模块，ES6 export用法
export default API
