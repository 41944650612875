import CryptoJS from 'crypto-js'

/**
 * Description AES CBC BASE64加密解密
 * @author 
 * @date 13:38 2022/3/31
 */
export default {
  // aes加密
  encrypt(word) {
    let key = CryptoJS.enc.Utf8.parse(JSON.parse(localStorage.getItem("wxUser")).aesData.key) // 16位
    let iv = CryptoJS.enc.Utf8.parse(JSON.parse(localStorage.getItem("wxUser")).aesData.iv)
    let encrypted = ''
    if (typeof word === 'string') {
      const srcs = CryptoJS.enc.Utf8.parse(word)
      encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
      })
    } else if (typeof word === 'object') {
      // 对象格式的转成json字符串
      const data = JSON.stringify(word)
      const srcs = CryptoJS.enc.Utf8.parse(data)
      encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
      })
    }
    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
  },
  // aes解密
  decrypt(word) {
    let key = CryptoJS.enc.Utf8.parse(JSON.parse(localStorage.getItem("wxUser")).aesData.key) // 16位
    let iv = CryptoJS.enc.Utf8.parse(JSON.parse(localStorage.getItem("wxUser")).aesData.iv)
    if (word) {
      let base64 = CryptoJS.enc.Base64.parse(word)
      let src = CryptoJS.enc.Base64.stringify(base64)
      var decrypt = CryptoJS.AES.decrypt(src, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
      })
      var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
      return decryptedStr.toString()
    } else {
      return word
    }
  },
  // aes加密
  encryptSys(word) {
    let key = CryptoJS.enc.Utf8.parse(JSON.parse(localStorage.getItem("user")).aesData.key) // 16位
    let iv = CryptoJS.enc.Utf8.parse(JSON.parse(localStorage.getItem("user")).aesData.iv)
    let encrypted = ''
    if (typeof word === 'string') {
      const srcs = CryptoJS.enc.Utf8.parse(word)
      encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
      })
    } else if (typeof word === 'object') {
      // 对象格式的转成json字符串
      const data = JSON.stringify(word)
      const srcs = CryptoJS.enc.Utf8.parse(data)
      encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
      })
    }
    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
  },
}